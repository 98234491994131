<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">快递业务</el-breadcrumb-item>
        <el-breadcrumb-item>月度对账单</el-breadcrumb-item>
      </el-breadcrumb>
    </template>

    <el-form class="search-form" @submit.prevent>
      <el-row>
        <el-form-item label="" label-width="0" prop="keyword">
          <el-col>
            <el-button type="info" icon="back" @click="back()">
              返回
            </el-button>
          </el-col>
        </el-form-item>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <el-form-item label="对账月份：" label-width="130px">
            <el-col>
              <el-input v-model="forms.billDate" style="max-width: 100%" disabled>
              </el-input>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :xs="6" :sm="6" :md="6" :lg="6" :xl="6">
          <el-form-item label="合作商：" label-width="130px">
            <el-select v-model="forms.partnerName" placeholder="请选择" style="max-width: 60%" disabled>
              <el-option v-for="item in dict.partners" :key="item.id" :label="item.partnerName" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-tabs type="border-card" v-model="tabName" @tab-change="loadTabData">
      <!-- 汇总表 -->
      <el-tab-pane name="MonthTotal">
        <template #label>
          <span>
            费用汇总
          </span>
        </template>

        <el-table :data="data.totalData" :span-method="spanMethod" style="width: 100%; margin:0px auto; color:#000"
          class="table" border size="small">
          <el-table-column prop="bizType" label="费用项目" width="130" align="center" />
          <el-table-column label="收费内容" align="center">
            <el-table-column prop="index" label="序号" width="55" align="center" />
            <el-table-column prop="methodName" label="内容" width="170" align="center" />
          </el-table-column>
          <el-table-column label="价格" align="center">
            <el-table-column prop="unitPrice" label="单价" width="60" align="center" />
            <el-table-column prop="scaleUnit" label="单位" width="110" align="center" />
          </el-table-column>
          <el-table-column label="作业量" align="center">
            <el-table-column prop="number" label="数量" width="60" align="center" />
            <el-table-column prop="unitName" label="单位" width="100" align="center" />
          </el-table-column>
          <el-table-column prop="subTotal" label="费用小计(元)" width="90" align="center" />
          <el-table-column prop="extTotal" label="特殊费用(元)" width="90" align="center" />
          <el-table-column prop="total" label="费用合计(元)" width="90" align="center" />
          <el-table-column prop="faxNumber" label="含税" width="60" align="center" />
          <el-table-column prop="remark" label="备注" align="center" />
        </el-table>
      </el-tab-pane>
      <!-- 入库明细 -->
      <el-tab-pane name="MonthPut">
        <template #label>
          <span>
            入库明细
          </span>
        </template>

        <el-form :inline="true" @submit.prevent>
          <el-select v-model="coverMark" placeholder="请选择本次数据上传方式" size="default" style="width:120px; margin-right:20px;">
            <el-option label="增量更新" value="0" />
            <el-option label="覆盖更新" value="1" />
          </el-select>
          <el-upload ref="upload" class="upload-box" action="MonthPut" :auto-upload="true" :limit="1"
            :http-request="upload">
            <template #trigger>
              <el-button type="primary">选择要导入的文件</el-button>
            </template>
          </el-upload>

          <div class="upload-tip">
            点击下载模板：
            <a target="_blank" :href="MonthPutUrl"> 月度账单入库费导入模板.xlsx </a>
          </div>
          <el-button type="primary" style="float:right;" icon="download"
            @click="loadTabData(null, 'Export')">导出</el-button>
        </el-form>

        <el-table class="table" ref="multipleTable" :data="data.monthPutData" style="width: 100%" border size="small"
          max-height="580">
          <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
          <el-table-column prop="storeName" label="区域/仓库" width="80"> </el-table-column>
          <el-table-column prop="productOwner" label="货主" width="120"> </el-table-column>
          <el-table-column label="入库时间" width="90">
            <template #default="scope">
              <span>{{ (scope.row.putDate || "").split(" ")[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="materialCode" label="物料编码" width="90" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="materialName" label="物料名称" width="140" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="number" label="实收个数" width="70"> </el-table-column>
          <el-table-column prop="volume" label="实收体积" width="70"> </el-table-column>
          <el-table-column prop="boxNumber" label="实收箱数" width="70"> </el-table-column>
          <el-table-column prop="operateRemark" label="入库备注" show-overflow-tooltip> </el-table-column>
        </el-table>
      </el-tab-pane>
      <!-- 出库费明细 -->
      <el-tab-pane name="MonthOut">
        <template #label>
          <span>
            出库费明细
          </span>
        </template>

        <el-form :inline="true" @submit.prevent>
          <el-select v-model="coverMark" placeholder="请选择本次数据上传方式" size="default" style="width:120px; margin-right:20px;">
            <el-option label="增量更新" value="0" />
            <el-option label="覆盖更新" value="1" />
          </el-select>

          <el-upload ref="upload" class="upload-box" action="MonthOut" :auto-upload="true" :limit="1"
            :http-request="upload">
            <template #trigger>
              <el-button type="primary">选择要导入的文件</el-button>
            </template>
          </el-upload>

          <div class="upload-tip">
            点击下载模板：
            <a target="_blank" :href="MonthOutUrl"> 月度账单出库费导入模板.xlsx </a>
          </div>

          <el-button type="primary" style="float:right;" icon="download"
            @click="loadTabData(null, 'Export')">导出</el-button>
        </el-form>

        <!-- <el-table class="table" ref="multipleTable" :data="data.monthOutData" style="width: 100%" border size="small" max-height="580">
          <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
          <el-table-column label="业务模式" width="110">
            <template #default="scope">
              <el-tooltip v-if="scope.row.status==-1||(scope.row.status==null)" class="item" effect="dark" :content="scope.row.remark" placement="top-start">
                <el-link type="danger" size="small" disabled>{{scope.row.bizType}}</el-link>
              </el-tooltip>
              <el-tooltip v-if="scope.row.status==1" class="item" effect="dark" :content="scope.row.remark" placement="top-start">
                <el-link type="success" size="small" disabled>{{scope.row.bizType}}</el-link>
              </el-tooltip>
            </template>
          </el-table-column>

          <el-table-column prop="productOwner" label="货主" width="65"> </el-table-column>
          <el-table-column prop="outOrderNo" label="出库单号" width="115"> </el-table-column>
          <el-table-column prop="orderType" label="订单类型" width="65"> </el-table-column>
          <el-table-column prop="bizNo" label="业务号码" width="90" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="firstState" label="首状态" width="60"> </el-table-column>
          <el-table-column prop="endedState" label="尾状态" width="60"> </el-table-column>
          <el-table-column prop="dealType" label="处理类型" width="65"> </el-table-column>
          <el-table-column label="复核完成时间" width="90">
            <template #default="scope">
              <span>{{ (scope.row.checkDate || "").split(" ")[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column label="发运时间" width="90">
            <template #default="scope">
              <span>{{ (scope.row.sendDate || "").split(" ")[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="emsNo" label="快递单号" width="110" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="companyName" label="快递公司" width="65" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="province" label="省份" width="70" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="city" label="城市" width="70" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="platformNo" label="平台"> </el-table-column>
          <el-table-column prop="brandNo" label="品牌"> </el-table-column>
          <el-table-column prop="materialCode" label="物料编码" width="70" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="materialName" label="物料名称" width="90" show-overflow-tooltip> </el-table-column>
          <el-table-column prop="firstNumber" label="首件" width="50"> </el-table-column>
          <el-table-column prop="additNumber" label="续件" width="50"> </el-table-column>
          <el-table-column prop="number" label="个数" width="50"> </el-table-column>
          <el-table-column prop="boxUnit" label="箱含量" width="60"> </el-table-column>
          <el-table-column prop="boxNumber" label="箱数" width="50"> </el-table-column>
          <el-table-column prop="volume" label="体积" width="50"> </el-table-column>
          <el-table-column prop="weight" label="重量" width="55"> </el-table-column>

          <el-table-column prop="outPayFee" label="操作费" width="45"> </el-table-column>
          <el-table-column prop="outSysFee" label="系统计算" width="70"> </el-table-column>

          <el-table-column label="状态" width="80">
            <template #default="scope">
              <el-tooltip v-if="!scope.row.status" class="item" effect="dark" :content="scope.row.remark" placement="top-start">
                <el-link type="danger" size="small" disabled>计算错误</el-link>
              </el-tooltip>
              <el-tooltip v-if="scope.row.status==-1" class="item" effect="dark" :content="scope.row.remark" placement="top-start">
                <el-link type="danger" size="small" disabled>差异</el-link>
              </el-tooltip>
              <el-tooltip v-if="scope.row.status==1" class="item" effect="dark" :content="scope.row.remark" placement="top-start">
                <el-link type="success" size="small" disabled>正常</el-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" width="180" show-overflow-tooltip> </el-table-column>
        </el-table> -->

        <static-table class="table" :pager="MonthOutParams.pager" :columns="MonthOutParams.columns"
          :currHandler="currHandler" :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
          <template v-slot:bizType="scope">
            <el-tooltip v-if="scope.row.status == -1 || (scope.row.status == null)" class="item" effect="dark"
              :content="scope.row.remark" placement="top-start">
              <el-link type="danger" size="small" disabled>{{ scope.row.bizType }}</el-link>
            </el-tooltip>
            <el-tooltip v-if="scope.row.status == 1" class="item" effect="dark" :content="scope.row.remark"
              placement="top-start">
              <el-link type="success" size="small" disabled>{{ scope.row.bizType }}</el-link>
            </el-tooltip>
          </template>
          <template v-slot:status="scope">
            <el-tooltip v-if="!scope.row.status" class="item" effect="dark" :content="scope.row.remark"
              placement="top-start">
              <el-link type="danger" size="small" disabled>计算错误</el-link>
            </el-tooltip>
            <el-tooltip v-if="scope.row.status == -1" class="item" effect="dark" :content="scope.row.remark"
              placement="top-start">
              <el-link type="danger" size="small" disabled>差异</el-link>
            </el-tooltip>
            <el-tooltip v-if="scope.row.status == 1" class="item" effect="dark" :content="scope.row.remark"
              placement="top-start">
              <el-link type="success" size="small" disabled>正常</el-link>
            </el-tooltip>
          </template>
        </static-table>

      </el-tab-pane>
      <!-- 快递费明细 -->
      <el-tab-pane name="MonthEms">
        <template #label>
          <span>
            快递费明细
          </span>
        </template>

        <el-form :inline="true" @submit.prevent>
          <el-select v-model="coverMark" placeholder="请选择本次数据上传方式" size="default" style="width:120px; margin-right:20px;">
            <el-option label="增量更新" value="0" />
            <el-option label="覆盖更新" value="1" />
          </el-select>

          <el-upload ref="upload" class="upload-box" action="MonthEms" :auto-upload="true" :limit="1"
            :http-request="upload">
            <template #trigger>
              <el-button type="primary">选择要导入的文件</el-button>
            </template>
          </el-upload>

          <div class="upload-tip">
            点击下载模板：
            <a target="_blank" :href="MonthEmsUrl"> 月度账单快递费导入模板.xlsx </a>
          </div>

          <el-button type="primary" style="float:right;" icon="download"
            @click="loadTabData(null, 'Export')">导出</el-button>
        </el-form>

        <!-- <el-table class="table" ref="multipleTable" :data="data.monthEmsData" style="width: 100%" border size="small" max-height="580">
          <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
          <el-table-column prop="companyName" label="快递公司" width="65"> </el-table-column>
          <el-table-column prop="expressName" label="快递类型" width="68"> </el-table-column>
          <el-table-column prop="detailNo" label="快递单号" sortable width="130"> </el-table-column>
          <el-table-column prop="emsPayType" label="付款方式" width="65"> </el-table-column>
          <el-table-column prop="originProvince" label="寄出省份" width="70"> </el-table-column>
          <el-table-column prop="originCity" label="寄出城市" width="70"> </el-table-column>
          <el-table-column prop="targetProvince" label="目标省份" width="120"> </el-table-column>
          <el-table-column prop="targetCity" label="目标城市" width="100"> </el-table-column>
          <el-table-column prop="emsWeight" label="计费重量" width="65"> </el-table-column>
          <el-table-column prop="emsPayFee" label="运费" width="45"> </el-table-column>
          <el-table-column prop="emsSysFee" label="系统计算" width="70"> </el-table-column>

          <el-table-column label="状态" width="80">
            <template #default="scope">
              <el-tooltip v-if="!scope.row.status" class="item" effect="dark" :content="scope.row.remark" placement="top-start">
                <el-link type="danger" size="small" disabled>计算错误</el-link>
              </el-tooltip>
              <el-tooltip v-if="scope.row.status==-1" class="item" effect="dark" :content="scope.row.remark" placement="top-start">
                <el-link type="danger" size="small" disabled>差异</el-link>
              </el-tooltip>
              <el-tooltip v-if="scope.row.status==1" class="item" effect="dark" :content="scope.row.remark" placement="top-start">
                <el-link type="success" size="small" disabled>正常</el-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" width="180" show-overflow-tooltip> </el-table-column>
        </el-table> -->

        <static-table class="table" :pager="MonthEmsParams.pager" :columns="MonthEmsParams.columns"
          :currHandler="currHandler" :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
          <template v-slot:status="scope">
            <el-tooltip v-if="!scope.row.status" class="item" effect="dark" :content="scope.row.remark"
              placement="top-start">
              <el-link type="danger" size="small" disabled>计算错误</el-link>
            </el-tooltip>
            <el-tooltip v-if="scope.row.status == -1" class="item" effect="dark" :content="scope.row.remark"
              placement="top-start">
              <el-link type="danger" size="small" disabled>差异</el-link>
            </el-tooltip>
            <el-tooltip v-if="scope.row.status == 1" class="item" effect="dark" :content="scope.row.remark"
              placement="top-start">
              <el-link type="success" size="small" disabled>正常</el-link>
            </el-tooltip>
          </template>
        </static-table>
      </el-tab-pane>

      <!-- 退货明细 -->
      <el-tab-pane name="tuihuo">
        <template #label>
          <span>
            退货明细
          </span>
        </template>

        <el-table class="table" ref="multipleTable" :data="data.tuihuoData" style="width: 100%" border size="small"
          max-height="580">
          <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
          <el-table-column prop="日期" label="日期" sortable width="110"> </el-table-column>
          <el-table-column prop="channel" label="运单号码" sortable width="90"> </el-table-column>
          <el-table-column prop="detailCode" label="寄件地区" sortable width="135"> </el-table-column>
          <el-table-column prop="detailName" label="到件地区" sortable width="125"> </el-table-column>
          <el-table-column prop="serialCode" label="物料编码" sortable width="110"> </el-table-column>
          <el-table-column prop="brandName" label="物料名称" sortable width="120"> </el-table-column>
          <el-table-column prop="brandSerialCode" label="个数" width="90"> </el-table-column>
          <el-table-column prop="brandSerialCode" label="收方信息" width="90"> </el-table-column>
          <el-table-column prop="unitName" label="备注"> </el-table-column>
          <el-table-column prop="brandSerialCode" label="费用(元)" width="100"> </el-table-column>
        </el-table>
      </el-tab-pane>

      <!-- 贴标费明细 -->
      <el-tab-pane name="tiebiao">
        <template #label>
          <span>
            贴标费明细
          </span>
        </template>

        <el-table class="table" ref="multipleTable" :data="data.tiebiaoData" style="width: 100%" border size="small"
          max-height="580">
          <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
          <el-table-column prop="storeCode" label="货主" sortable width="110"> </el-table-column>
          <el-table-column prop="channel" label="出库单号" sortable width="90"> </el-table-column>
          <el-table-column prop="detailCode" label="建单时间" sortable width="135"> </el-table-column>
          <el-table-column prop="detailName" label="发运时间" sortable width="125"> </el-table-column>
          <el-table-column prop="serialCode" label="物料编码" sortable width="110"> </el-table-column>
          <el-table-column prop="brandName" label="物料名称" sortable width="120"> </el-table-column>
          <el-table-column prop="brandSerialCode" label="个数" width="90"> </el-table-column>
          <el-table-column prop="brandSerialCode" label="箱含量" width="90"> </el-table-column>
          <el-table-column prop="brandSerialCode" label="箱数" width="90"> </el-table-column>
          <el-table-column prop="brandSerialCode" label="体积" width="90"> </el-table-column>
          <el-table-column prop="brandSerialCode" label="重量" width="90"> </el-table-column>
          <el-table-column prop="brandSerialCode" label="重量" width="90"> </el-table-column>
          <el-table-column prop="unitName" label="备注"> </el-table-column>
          <el-table-column prop="certNumber" label="贴标数量" width="90"> </el-table-column>
          <el-table-column prop="certNumber" label="贴箱唛数量" width="110"> </el-table-column>
          <el-table-column prop="certNumber" label="外箱字样数量" width="120"> </el-table-column>
          <el-table-column prop="certNumber" label="贴标数量汇总" width="120"> </el-table-column>
          <el-table-column prop="certNumber" label="贴标费率（元/个）" width="120"> </el-table-column>
          <el-table-column prop="certNumber" label="费用" width="90"> </el-table-column>
        </el-table>
      </el-tab-pane>

      <!-- 普通物流费 -->
      <el-tab-pane name="wuliu">
        <template #label>
          <span>
            普通物流费
          </span>
        </template>

        <el-table class="table" ref="multipleTable" :data="data.wuliuData" style="width: 100%" border size="small"
          max-height="580">
          <el-table-column type="index" label="序号" align="center" width="50"> </el-table-column>
          <el-table-column prop="storeCode" label="发运日期" sortable width="120"> </el-table-column>
          <el-table-column prop="channel" label="物料名称" sortable width="120"> </el-table-column>
          <el-table-column prop="serialCode" label="线路" sortable width="160"> </el-table-column>
          <el-table-column prop="brandName" label="车牌" sortable width="100"> </el-table-column>
          <el-table-column prop="unitName" label="费用" width="55"> </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </el-card>
</template>
<script>
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import { uploader } from "@/api/subupload";
import { } from "@/api/mailscale";
import {
  fetchPartnerList, fetchMonthBill, fetchMonthTotal,
  importMonthEms, fetchMonthEmsList, fetchMonthEmsPager,
  importMonthOut, fetchMonthOutList, fetchMonthOutPager,
  importMonthPut, fetchMonthPutList,
} from "@/api/mailscale";
export default {
  name: "MailUnionDetail",
  components: { StaticDialog, StaticTable },
  data() {
    return {
      InTmplUrl: process.env.VUE_APP_BASEURL + "/static/template/MailOutTmplScale.xlsx",
      MonthPutUrl: process.env.VUE_APP_BASEURL + "/static/template/MailMonthPut.xlsx",
      MonthEmsUrl: process.env.VUE_APP_BASEURL + "/static/template/MailMonthEms.xlsx",
      MonthOutUrl: process.env.VUE_APP_BASEURL + "/static/template/MailMonthOut.xlsx",
      tabName: "MonthTotal",
      forms: {},
      MonthEmsParams: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { prop: "companyName", label: "快递公司", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "expressName", label: "快递类型", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "detailNo", label: "快递单号", width: "130", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "emsPayType", label: "付款方式", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "originProvince", label: "寄出省份", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "originCity", label: "寄出城市", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "targetProvince", label: "目标省份", width: "120", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "targetCity", label: "目标城市", width: "100", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "emsWeight", label: "计费重量", width: "90", sortable: true, },
          { prop: "emsPayFee", label: "导入运费", width: "90", sortable: true, },
          { prop: "emsSysFee", label: "系统计算", width: "120", sortable: true, },

          { type: "template", label: "核价状态", "prop": "status" },
          { prop: "remark", label: "备注", width: "130", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "createDate", label: "导入时间", width: "150", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "createUserName", label: "导入人", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
        ],
        pager: {
          sidx: "id", //默认的排序字段
          sord: "descending",
        },
      },

      MonthOutParams: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { type: "template", label: "业务模式", prop: "bizType", width: "140" },

          { prop: "productOwner", label: "货主", width: "65", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "outOrderNo", label: "出库单号", width: "115", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "orderType", label: "订单类型", width: "65", searchable: true, showOverflowTooltip: true },
          { prop: "bizNo", label: "业务号码", width: "90", searchable: true, showOverflowTooltip: true },
          { prop: "firstState", label: "首状态", width: "55", searchable: true, showOverflowTooltip: true },
          { prop: "endedState", label: "尾状态", width: "55", searchable: true, showOverflowTooltip: true },
          { prop: "dealType", label: "处理类型", width: "65", searchable: true, showOverflowTooltip: true },
          { prop: "checkDate", label: "复核完成时间", width: "140", sortable: true, },
          { prop: "sendDate", label: "发运时间", width: "140", sortable: true, },
          { prop: "emsNo", label: "快递单号", width: "130", sortable: true, showOverflowTooltip: true },
          { prop: "companyName", label: "快递公司", width: "90", sortable: true, },
          { prop: "province", label: "省份", width: "70", sortable: true, },
          { prop: "city", label: "城市", width: "70", sortable: true, },
          { prop: "materialCode", label: "物料编码", width: "90", sortable: true, showOverflowTooltip: true },
          { prop: "materialName", label: "物料名称", width: "110", sortable: true, showOverflowTooltip: true },
          { prop: "firstNumber", label: "首件", width: "50" },
          { prop: "additNumber", label: "续件", width: "50" },
          { prop: "number", label: "个数", width: "65" },
          { prop: "boxUnit", label: "箱含量", width: "65" },
          { prop: "boxNumber", label: "箱数", width: "65" },
          { prop: "volume", label: "体积", width: "65" },
          { prop: "weight", label: "重量", width: "65" },

          { prop: "outPayFee", label: "操作费", width: "85", sortable: true, },
          { prop: "outSysFee", label: "系统计算", width: "95", sortable: true, },

          { type: "template", label: "核价状态", "prop": "status" },
          { prop: "remark", label: "备注", width: "130", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "createDate", label: "导入时间", width: "150", sortable: true, searchable: true, showOverflowTooltip: true },
          { prop: "createUserName", label: "导入人", width: "90", sortable: true, searchable: true, showOverflowTooltip: true },
        ],
        pager: {
          sidx: "id", //默认的排序字段
          sord: "descending",
        },
      },
      coverMark: '1',
      data: {
      },
      dict: {
        partners: []
      }
    };
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    searchEvent() {

    },

    submitEvent() {

    },
    exportEvent() {
    },
    upload(file) {
      var dataList = new FormData();
      dataList.append(file.file.name, file.file);
      dataList.append("fileType", "excel");
      dataList.append("dataType", file.action);
      this.$refs.upload.clearFiles();

      uploader(dataList)
        .then((res) => {
          if (res.code === 200) {
            this.$message({ message: "上传成功", type: "success" });
            this.dataId = res.data[0].id;
          } else {
            this.$message.error("上传失败，请重试");
          }
        })
        .then(() => {
          this.loading = false;

          if (file.action == 'MonthEms') {
            importMonthEms(this.dataId, this.id, this.coverMark).then(
              (res) => {
                if (res.code === 200) {
                  this.loadTabData();
                }
              },
              (err) => {
                console.log(err);
              }
            );
          } else if (file.action == 'MonthOut') {
            importMonthOut(this.dataId, this.id, this.coverMark).then(
              (res) => {
                if (res.code === 200) {
                  this.loadTabData();
                }
              },
              (err) => {
                console.log(err);
              }
            );
          } else if (file.action == 'MonthPut') {
            importMonthPut(this.dataId, this.id, this.coverMark).then(
              (res) => {
                if (res.code === 200) {
                  this.loadTabData();
                }
              },
              (err) => {
                console.log(err);
              }
            );
          }

        });
    },
    loadTabData(changeTabName, method) {
      changeTabName = (changeTabName || this.tabName);
      if (changeTabName == 'MonthTotal') {
        fetchMonthTotal(this.id, method).then((res) => {
          if (method == 'Export') {
            this.$notify({
              title: "导出成功",
              message: res.content,
              type: "success",
              duration: 10000
            });
            window.location.href = process.env.VUE_APP_BASEURL + res.data;
          } else
            this.data.totalData = res.data;
        });
      } else if (changeTabName == 'MonthEms') {
        this.MonthEmsParams.filters = [];
        let { data, records, total, ...search } = {
          ...this.MonthEmsParams.pager,
          ...this.MonthEmsParams,
        };

        fetchMonthEmsPager(search, this.id, method).then((res) => {
          if (method == 'Export') {
            this.$notify({
              title: "导出成功",
              message: res.content,
              type: "success",
              duration: 10000
            });
            window.location.href = process.env.VUE_APP_BASEURL + res.data;
          } else {
            //this.data.monthEmsData = res.data.data;
            this.MonthEmsParams.pager = { ...res.data };
          }
        });
      } else if (changeTabName == 'MonthOut') {
        // fetchMonthOutList(this.id, method).then((res) => {
        //   if (method == 'Export') {
        //     this.$notify({
        //       title: "导出成功",
        //       message: res.content,
        //       type: "success",
        //       duration: 10000
        //     });
        //     window.open(process.env.VUE_APP_BASEURL + res.data);
        //   } else
        //     this.data.monthOutData = res.data.data;
        // });
        this.MonthOutParams.filters = [];
        let { data, records, total, ...search } = {
          ...this.MonthOutParams.pager,
          ...this.MonthOutParams,
        };

        fetchMonthOutPager(search, this.id, method).then((res) => {
          if (method == 'Export') {
            this.$notify({
              title: "导出成功",
              message: res.content,
              type: "success",
              duration: 10000
            });
            window.location.href = process.env.VUE_APP_BASEURL + res.data;
          } else {
            //this.data.monthEmsData = res.data.data;
            this.MonthOutParams.pager = { ...res.data };
          }
        });
      } else if (changeTabName == 'MonthPut') {
        fetchMonthPutList(this.id, method).then((res) => {
          if (method == 'Export') {
            this.$notify({
              title: "导出成功",
              message: res.content,
              type: "success",
              duration: 10000
            });
            window.location.href = process.env.VUE_APP_BASEURL + res.data;
          } else
            this.data.monthPutData = res.data.data;
        });
      }
    },
    spanMethod({ row, column, rowIndex, columnIndex }) {
      //存储服务费
      if (rowIndex === 0 && columnIndex === 0) {
        return {
          rowspan: 2,
          colspan: 1,
        }
      }
      if (rowIndex === 1 && columnIndex == 0) {
        return {
          rowspan: 0,
          colspan: 0,
        }
      }

      //入库作业费
      if (rowIndex === 2 && columnIndex === 0) {
        return {
          rowspan: 2,
          colspan: 1,
        }
      }
      if (rowIndex === 3 && columnIndex == 0) {
        return {
          rowspan: 0,
          colspan: 0,
        }
      }

      //B2C出库业务
      if (rowIndex === 4 && columnIndex === 0) {
        return {
          rowspan: 3,
          colspan: 1,
        }
      }
      if ((rowIndex === 5 || rowIndex === 6) && columnIndex == 0) {
        return {
          rowspan: 0,
          colspan: 0,
        }
      }

    },

    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      if (this.tabName == 'MonthTotal') { }
      else if (this.tabName == 'MonthEms') {
        this.MonthEmsParams.pager.page = page;
      }
      else if (this.tabName == 'MonthOut') {
        this.MonthOutParams.pager.page = page;
      }
      else if (this.tabName == 'MonthPut') { }

      this.loadTabData();
    },
    sizeHandler(rows) {
      if (this.tabName == 'MonthTotal') { }
      else if (this.tabName == 'MonthEms') {
        this.MonthEmsParams.pager.rows = rows;
      }
      else if (this.tabName == 'MonthOut') {
        this.MonthOutParams.pager.rows = rows;
      }
      else if (this.tabName == 'MonthPut') { }
      this.loadTabData();
    },
    sortHandler(order) {
      if (this.tabName == 'MonthTotal') { }
      else if (this.tabName == 'MonthEms') {
        this.MonthEmsParams.pager.sidx = order.prop;
        this.MonthEmsParams.pager.sord = order.order;
      }
      else if (this.tabName == 'MonthOut') {
        this.MonthEmsParams.pager.sidx = order.prop;
        this.MonthEmsParams.pager.sord = order.order;
      }
      else if (this.tabName == 'MonthPut') { }
      this.loadTabData();
    }
  },
  //在Vue的生命周期Created函数中注册相关事件
  created() {
  },
  mounted() {
    var that = this;
    that.id = that.$route.query.id;
    fetchPartnerList({ columns: [{ prop: 'id', prop: 'partnerName' }] }).then((res) => {
      that.dict.partners = res.data.data;
      if (that.id) {
        fetchMonthBill(that.id).then((res) => {
          that.forms = { ...res.data };
        });
      }
    });
    this.loadTabData();
  },
  computed: {
  },
};
</script>

<style>
.upload-box {
  display: inline-block;
}

.upload-tip {
  font-size: 12px;
  margin-left: 12px;
  display: inline-block;
}

.el-table thead.is-group th.el-table__cell {
  color: #000;
  font-size: 1.2em;
}

.el-table td.el-table__cell {
  font-size: 0.8em;
}
</style>